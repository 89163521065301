@import './utilities';

.projects-preview-section-container {
    a {
        text-decoration: none;
        color: color(textcolor);
    }
    .projects-preview-header-container {
        height: 48vh;
        margin-top: 11vh;
        position: absolute;
        width: 100vw;
        background-image: url('../images/plus-overlay.png');
        background-repeat: repeat;
        background-size: 1.2em 1.2em;
        opacity: .25;
        z-index: -1;
    }

    .design-wrapper {
        position: relative;
        top: 15vh;
    }

    .design-item-container {
        padding-left: 90px;
        width: 100%;
        opacity: 1;
        
        .card-top-design {
            height: 160px;
            font-size: 4rem;
            letter-spacing: 1px;
            font-family: 'Open Sans', 'Aktiv Grotesk';
            font-weight: 600;
            color: color(textcolor);
            text-decoration: underline;
        }
    
        .design-item {
            display: inline-block;
            height: 150px;
            vertical-align: top;
        }
    
        .col-1 {
            width: 7%;
        }
        .design-tag {
            margin: 0;
            font-size: .7em;
            padding-top: 5.3em;
            letter-spacing: 1px;
            font-family: 'menlo';
            font-weight: 500;
            text-transform: uppercase;
            color: color(textcolor);
        }
    
        .col-2 {
            width: 44%;
        }

        .design-main-content {
            margin-top: 25px;
            height: 25px;
            font-size: .8em;
            letter-spacing: 1px;
            font-family: 'menlo';
            font-weight: 300;
            text-transform: uppercase;
            color: color(terniary);
        }
        .design-description {
            font-family: 'Roboto';
            font-size: 1.5em;
            font-weight: 400;
            color: lighten(color(textcolor), 20%);
        }
    }

    .design-handler-wrapper {
        position: relative;
        left: 65vw;
        width: 15em;
        height: 15em;
        border-radius: 50%;
        background-color: rgb(40,38,51);
        color: color(primary);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .design-handler-wrapper a {
        width: 60%;
        text-align: center;
        text-decoration: underline;
        color: color(primary);
    }

    .design-handler-wrapper a h3 {
        font-family: 'Open Sans', 'Aktiv Grotesk';
        font-weight: 400;
        font-size: 1.7rem;
        padding-bottom: 2px;
    }

    .preview-projects-title {
        margin-top: 20vh;
    }

    // Project Preview Home Page
    .projects-preview-container {
        padding-left: 90px;
        width: 100%;
        opacity: 1;
    
        .projects-preview-section {
            display: inline-block;
            height: 150px;
            vertical-align: top;
        }
    
        .col-1 {
            width: 7%;
        }
        .card-tag {
            margin: 0;
            font-size: .7em;
            padding-top: 10px;
            letter-spacing: 1px;
            font-family: 'menlo';
            font-weight: 500;
            text-transform: uppercase;
            color: color(textcolor);
        }
    
        .col-2 {
            width: 20%;
            margin-right: 5%;
        }
        .card-main-content {
            font-size: 1.6em;
            line-height: 1.1;
            font-family: 'Roboto';
            letter-spacing: 1px;
            padding-right: 10%;
            font-weight: 400;
            text-decoration: underline;
        }

        .card-description{
            font-family: 'Open Sans', 'Aktiv Grotesk';
            margin-top: 15px;
            font-weight: 500;
        }
    }
}

@include phone {
    .projects-preview-section-container {
        .projects-preview-header-container {
            margin-top: 1vh;
            height: 45vh;
        }

        .design-wrapper {
            top: 7vh;
        }

        .design-item-container {
            padding-left: 20px;
            width: 90vw;

            .card-top-design {
                font-size: 2rem;
                height: 100px;
            }
            .design-tag {
                font-size: .6em;
            }
            .design-main-content {
                font-size: .6em;
                margin-top: 20px;
            }
            .design-description {
                font-size: 1.1em;
            }
        }
        .design-handler-wrapper {
            width: 11em;
            height: 11em;
            left: 50vw;
            top: 3vh;
        }

        .design-handler-wrapper a h3 {
            font-size: 1.3rem;
        }

        .projects-preview-container {
            padding-left: 20px;

            .card-tag {
                font-size: .6em;
            }
            .card-top {
                font-size: .6em;
            }
            .col-2 {
                width: 25%;
            }

            .card-main-content {
                font-size: 1em;
            }
            .card-description {
                font-size: .8em;
            }
        }
    }
}

@include phone-land {
    .projects-preview-section-container {
        .projects-preview-header-container {
            margin-top: 1vh;
            height: 250px;
        }

        .design-wrapper {
            top: 7vh;
        }

        .design-item-container {
            padding-left: 20px;
            width: 90vw;

            .card-top-design {
                font-size: 2rem;
                height: 40px;
            }
            .design-tag {
                font-size: .6em;
            }
            .design-main-content {
                font-size: .6em;
                margin-top: 20px;
            }

            .col-2 {
                width: 60%;
            }
            .design-description {
                font-size: 1.1em;
            }
        }
        .design-handler-wrapper {
            width: 11em;
            height: 11em;
            left: 65vw;
            top: -50px;
        }

        .design-handler-wrapper a h3 {
            font-size: 1.3rem;
        }

        .projects-preview-container {
            padding-left: 20px;

            .card-tag {
                font-size: .6em;
            }
            .card-top {
                font-size: .6em;
            }
            .col-2 {
                width: 25%;
            }

            .card-main-content {
                font-size: 1em;
            }
            .card-description {
                font-size: .8em;
            }
        }

        .preview-projects-title {
            margin-top: 0;
        }
    }
}

@include ipad {
    .projects-preview-section-container {
        .projects-preview-header-container {
            margin-top: 1vh;
            height: 250px;
        }

        .design-wrapper {
            top: 7vh;
        }

        .design-item-container {
            padding-left: 20px;
            width: 90vw;

            .card-top-design {
                font-size: 2rem;
                height: 40px;
            }
            .design-tag {
                font-size: .6em;
            }
            .design-main-content {
                font-size: .6em;
                margin-top: 20px;
            }

            .col-2 {
                width: 60%;
            }
            .design-description {
                font-size: 1.1em;
            }
        }
        .design-handler-wrapper {
            width: 11em;
            height: 11em;
            left: 65vw;
            top: -50px;
        }

        .design-handler-wrapper a h3 {
            font-size: 1.3rem;
        }

        .projects-preview-container {
            padding-left: 20px;

            .card-tag {
                font-size: .6em;
            }
            .card-top {
                font-size: .6em;
            }
            .col-2 {
                width: 25%;
            }

            .card-main-content {
                font-size: 1em;
            }
            .card-description {
                font-size: .8em;
            }
        }

        .preview-projects-title {
            margin-top: 0;
        }
    }
}