@import './utilities';

.page-hero-text-box {
    width: 90vw;

    .page-header-title {
        padding-left: 90px;
        font-size: 3rem;
        width: 65%;
        line-height: 1.2;
        letter-spacing: .5px;
        font-family: 'Open Sans', 'Aktiv Grotesk';
        font-weight: 500;
        color: color(textcolor);
    }
}

#root {
    margin-top: 14vh;
    overflow: hidden;
    overflow-y: scroll;
}

.home-header {
    margin-top: 6.5em;
}

.page-mission {
    margin-top: 70px;
    padding-left: 90px;
    width: 100%;
    color: color(textcolor);

    .mission-section {
        display: inline-block;
        height: 150px;
        vertical-align: top;
    }

    .col-1 {
        width: 7%;
    }
    .card-tag {
        margin: 0;
        font-size: .7em;
        padding-top: 10px;
        letter-spacing: 1px;
        font-family: 'menlo';
        font-weight: 500;
        text-transform: uppercase;
        color: color(textcolor);
    }

    .col-2 {
        width: 50%;
    }
    .card-main-content {
        font-size: 1.5em;
        line-height: 1.3;
        font-family: 'Roboto';
        letter-spacing: 1px;
        padding-right: 10%;
        font-weight: 400;
    }

    .col-3 {
        width: 15%;
    }
    .card-education-content,
    .card-ventures-content {
        font-family: 'Roboto';
        padding-right: 20%;
        text-decoration: underline;
        font-size: 1em;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .col-4 {
        width: 15%;
    }
}

@include phone {
    .page-hero-text-box {
        .page-header-title {
            padding-left: 30px;
            padding-right: 30px;
            font-size: 1.6rem;
            width: 85vw;
        }
    }

    .home-header {
        margin-top: 6em;
    }

    .page-mission {
        padding-left: 20px;

        .card-tag {
            font-size: .6em;
            padding-top: 5px;
        }
        
        .col-2 {
            width: 80%;
        }
        .card-main-content {
            font-size: 1em;
        }

        .col-3,
        .col-4 {
            width: 40%;
            margin-left: 25px;
        }

        .card-education-content,
        .card-ventures-content {
            font-size: .7em;
        }
    }
}

@include phone-land {
    .page-hero-text-box {
        .page-header-title {
            padding-left: 40px;
            padding-right: 40px;
            font-size: 1.6rem;
            width: 85vw;
        }
    }

    .home-header {
        margin-top: 6em;
    }

    .page-mission {
        padding-left: 30px;

        .card-tag {
            font-size: .6em;
            padding-top: 5px;
        }

        .card-top {
            font-size: .6em;
        }
        
        .col-2 {
            width: 80%;
        }
        .card-main-content {
            font-size: 1.2em;
        }

        .col-3,
        .col-4 {
            width: 40%;
            margin-left: 25px;
        }

        .card-education-content,
        .card-ventures-content {
            font-size: .8em;
        }
    }
}

@include ipad {
    .page-hero-text-box {
        .page-header-title {
            padding-left: 40px;
            padding-right: 40px;
            font-size: 1.6rem;
            width: 85vw;
        }
    }

    .home-header {
        margin-top: 6em;
    }

    .page-mission {
        padding-left: 30px;

        .card-tag {
            font-size: .6em;
            padding-top: 5px;
        }

        .card-top {
            font-size: .6em;
        }
        
        .col-2 {
            width: 80%;
        }
        .card-main-content {
            font-size: 1.2em;
        }

        .col-3,
        .col-4 {
            width: 40%;
            margin-left: 25px;
        }

        .card-education-content,
        .card-ventures-content {
            font-size: .8em;
        }
    }
}