@import '../../../styles/utilities';

.template-wrapper {
    margin-top: 24px;
    padding-left: 90px;
    width: 70vw;
    color: color(textcolor);

    .mission-section {
        display: inline-block;
        height: 150px;
        vertical-align: top;
    }

    .col-1 {
        width: 7%;
    }
    .card-tag {
        margin: 0;
        font-size: .7em;
        padding-top: 10px;
        letter-spacing: 1px;
        font-family: 'menlo';
        font-weight: 500;
        text-transform: uppercase;
        color: color(textcolor);
    }

    .col-2 {
        width: 90%;
    }
    .card-main-content {
        font-size: 1.2em;
        line-height: 1.3;
        font-family: 'Roboto';
        letter-spacing: 1px;
        padding-right: 10%;
        font-weight: 400;
    }
}

.next-wrapper {
    background-color: color(wallpaper);
    margin: 70px 70px;
    height: 200px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
        font-family: 'menlo';
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 400;
        margin-right: 40px;
        margin-left: 40px;
    }
    .title a {
        color: color(textcolor);
    }
}

.visit-wrapper {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    background-color: rgb(40,38,51);
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        font-size: 1.1em;
        width: 70%;
        text-align: center;
        color: color(primary);
        font-family: 'Open Sans', 'Aktiv Grotesk';
    }
}

@include phone {
    .template-wrapper {
        padding-left: 20px;
        width: 90vw;

        .card-tag,
        .card-top {
            font-size: .6em;
        }
    
        .card-main-content {
            font-size: .8em;
        }
    }

    .next-wrapper {
        margin: 20px 20px;
        .title {
            font-size: 1rem;
            text-align: center;
        }
    }

    .visit-wrapper {
        height: 120px;
        width: 120px;

        a {
            font-size: .7em;
        }
    }
}

@include phone-land {
    .template-wrapper {
        padding-left: 20px;
        width: 90vw;

        .card-tag,
        .card-top {
            font-size: .6em;
        }
    
        .card-main-content {
            font-size: 1em;
        }
    }

    .next-wrapper {
        margin: 20px 20px;
        .title {
            font-size: 1rem;
            text-align: center;
        }
    }

    .visit-wrapper {
        height: 180px;
        width: 180px;

        a {
            font-size: 1em;
        }
    }
}

@include ipad {
    .template-wrapper {
        padding-left: 20px;
        width: 90vw;

        .card-tag,
        .card-top {
            font-size: .6em;
        }
    
        .card-main-content {
            font-size: 1em;
        }
    }

    .next-wrapper {
        margin: 20px 20px;
        .title {
            font-size: 1rem;
            text-align: center;
        }
    }

    .visit-wrapper {
        height: 180px;
        width: 180px;

        a {
            font-size: 1em;
        }
    }
}