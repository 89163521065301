@import '../../styles/utilities';

#aughotti-wallpaper{
    width: 100%;
    height: 50vh;
    background: url('https://s3-us-west-1.amazonaws.com/music-stream-vschool/aughotti.png');
    background-size: cover;
    background-position: center top;
}

@include phone {
    #aughotti-wallpaper{
        width: 100%;
        height: 110px;
        background: url('https://s3-us-west-1.amazonaws.com/music-stream-vschool/aughotti.png');
        background-size: cover;
        background-position: center top;
    }
}

@include phone-land {
    #aughotti-wallpaper{
        width: 100%;
        height: 50vh;
        background: url('https://s3-us-west-1.amazonaws.com/music-stream-vschool/aughotti.png');
        background-size: cover;
        background-position: center top;
    }
}

@include ipad {
    #aughotti-wallpaper{
        width: 100%;
        height: 250px;
        background: url('https://s3-us-west-1.amazonaws.com/music-stream-vschool/aughotti.png');
        background-size: cover;
        background-position: center top;
    }
}