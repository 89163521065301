@import './utilities';

.navbar-wrapper {
    position: fixed;
    top: 0;
    height: 14vh;
    width: 100%;
    z-index: 20;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Roboto';
    font-size: .95rem;
    font-weight: 400;
    background-color: color(primary);
    
    .navbar-logo {
        .navbar-logo-line,
        .navbar-logo-line:active {
            color: color(terniary);
            margin-left: 70px;
        }

        h4,
        h4:active {
            color: color(textcolor);
            font-weight: 400;
            margin: 0;
            display: inline-block;
        }
    }

    .navbar-links {
        margin-right: 70px;
        li,
        li:active {
            color: color(textcolor);
            display: inline-block;
            list-style: none;
            margin-left: 3em;
        }
    }

    @include phone {
        font-size: .7rem;
        .navbar-logo {
            .navbar-logo-line,
            .navbar-logo-line:active {
                margin-left: 20px;
            }
        }
        .navbar-links {
            margin-right: 20px;
            li,
            li:active {
                color: color(textcolor);
                display: inline-block;
                list-style: none;
                margin-left: 2em;
            }
        }
    }

    @include phone-land {
        font-size: 1rem;
        .navbar-logo {
            .navbar-logo-line,
            .navbar-logo-line:active {
                margin-left: 30px;
            }
        }
        .navbar-links {
            margin-right: 30px;
            li,
            li:active {
                color: color(textcolor);
                display: inline-block;
                list-style: none;
                margin-left: 2em;
            }
        }
    }

    @include ipad {
        font-size: 1rem;
        .navbar-logo {
            .navbar-logo-line,
            .navbar-logo-line:active {
                margin-left: 30px;
            }
        }
        .navbar-links {
            margin-right: 30px;
            li,
            li:active {
                color: color(textcolor);
                display: inline-block;
                list-style: none;
                margin-left: 2em;
            }
        }
    }
}