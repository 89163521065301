@import '../../styles/utilities';

#bounty-wallpaper{
    margin-top: 90px;
    width: 100%;
    height: 65vh;
    background: url('https://s3-us-west-1.amazonaws.com/music-stream-vschool/bounty-main.png');
    background-size: cover;
    background-position: center top;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
}

#bounty-wallpaper-2 {
    width: 100vw;
    height: 80vh;
    background: url('https://s3-us-west-1.amazonaws.com/music-stream-vschool/bounty.png');
    background-size: cover;
}

.bounty-link {
    position: absolute;
    left: 70vw;
    top: 220vh;
}

@include phone {
    .bounty-link {
        left: 60vw;
        top: 90vh;
    }
    #bounty-wallpaper{
        margin-top: 120px;
        height: 300px;
    }
    #bounty-wallpaper-2{
        height: 150px;
    }
}
@include phone-land {
    .bounty-link {
        left: 60vw;
        top: 750px;
    }
    #bounty-wallpaper{
        margin-top: 120px;
        height: 400px;
    }
    #bounty-wallpaper-2{
        height: 350px;
    }
}

@include ipad {
    .bounty-link {
        left: 60vw;
        top: 750px;
    }
    #bounty-wallpaper{
        margin-top: 120px;
        height: 400px;
    }
    #bounty-wallpaper-2{
        height: 350px;
    }
}