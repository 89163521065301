@import '../../styles/utilities';

#finstagram-wallpaper{
    width: 100%;
    height: 80vh;
    margin-top: 60px;
    background: url('https://s3-us-west-1.amazonaws.com/music-stream-vschool/fin.png');
    background-size: cover;
    background-position: center top;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.1);
}

#finstagram-wallpaper-2 {
    width: 100vw;
    height: 90vh;
    background: url('https://s3-us-west-1.amazonaws.com/music-stream-vschool/finstagram.png');
    background-size: cover;
}

.finstagram-link {
    position: absolute;
    left: 70vw;
    top: 150vh;
}

@include phone {
    .finstagram-link {
        top: 130vh;
        left: 60vw;
    }
    #finstagram-wallpaper{
        height: 250px;
    }

    #finstagram-wallpaper-2 {
        height: 180px;
    }
}

@include phone-land{
    .finstagram-link {
        top: 120vh;
        left: 60vw;
    }
    #finstagram-wallpaper{
        height: 300px;
    }

    #finstagram-wallpaper-2 {
        height: 400px;
    }
}

@include ipad{
    .finstagram-link {
        top: 120vh;
        left: 60vw;
    }
    #finstagram-wallpaper{
        height: 300px;
    }

    #finstagram-wallpaper-2 {
        height: 400px;
    }
}