@import '../../../styles/utilities';


.projects-wrapper {
    margin-top: 100px;
}
.project-item-container {
    margin-top: 40px;
    padding-left: 90px;
    width: 100%;
    opacity: 1;
    
    .card-top {
        height: 25px;
        font-size: .8em;
        letter-spacing: 1px;
        font-family: 'menlo';
        font-weight: 300;
        text-transform: uppercase;
        color: color(terniary);
    }

    .project-item {
        display: inline-block;
        height: 150px;
        vertical-align: top;
    }

    .project-item a {
        text-decoration: none;
    }

    .col-1 {
        width: 6%;
    }
    .project-tag {
        margin: 0;
        font-size: .7em;
        padding-top: 3em;
        letter-spacing: 1px;
        font-family: 'menlo';
        font-weight: 500;
        text-transform: uppercase;
        color: color(text-color);
    }

    .col-2 {
        width: 80%;
    }
    .project-main-content {
        font-size: 4rem;
        color: color(textcolor);
        opacity: 1;
        line-height: 1.2;
        font-family: 'Open Sans', 'Aktiv Grotesk';
        padding-right: 10%;
        font-weight: 400;
        text-decoration: underline;
    }
    .project-description {
        margin-top: 10px;
        font-family: 'Roboto';
        font-size: 1.5em;
        font-weight: 400;
        color: lighten(color(textcolor), 20%);
    }
}

@include phone {
    .projects-wrapper {
        margin-top: 40px;
    }
    .project-item-container {
        padding-left: 20px;
        width: 90vw;
        margin-top: 0;

        .project-tag {
            font-size: .6em;
        }

        .project-main-content {
            font-size: 1.5rem;
            font-weight: 600;
        }

        .project-description {
            font-size: .8rem;
        }
    }
    #projects-footer {
        margin-top: 0px;
    }
}

@include phone-land{
    .projects-wrapper {
        margin-top: 40px;
    }
    .project-item-container {
        padding-left: 30px;
        width: 90vw;
        margin-top: 0;

        .project-tag {
            font-size: .6em;
        }

        .card-top{
            font-size: .6em;
        }

        .project-main-content {
            font-size: 2.2rem;
            font-weight: 600;
        }

        .project-description {
            font-size: 1rem;
        }
    }
    #projects-footer {
        margin-top: 0px;
    }
}

@include ipad{
    .projects-wrapper {
        margin-top: 40px;
    }
    .project-item-container {
        padding-left: 30px;
        width: 90vw;
        margin-top: 0;

        .project-tag {
            font-size: .6em;
        }

        .card-top{
            font-size: .6em;
        }

        .project-main-content {
            font-size: 2.2rem;
            font-weight: 600;
        }

        .project-description {
            font-size: 1rem;
        }
    }
    #projects-footer {
        margin-top: 0px;
    }
}